//@flow

// Vendors
import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

// Helpers
import { sendMessageToNativeApp } from '../../../../lib/helpers';
import { getCentralOffset } from '../../../../lib/datetime';

// Data
import { APP_CONSTANTS } from '../../../../lib/data';

// Graphics
import { ReactComponent as ServerErrorSvg } from '../../../../assets/server-error.svg';

// Components
import { Modal, Trans } from '../..';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { FormActions, FormActionsItem } from '../../Forms';

// Selectors
import { selectIsAuthenticated } from '../../../../reducers/auth/authSelectors';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Styles
import './socure-service-down-modal.scss';

const SocureServiceDownModal = ({ modal: { data = {} } = {} }) => {
  const serviceType = data.service;

  const dispatch = useDispatch();

  const isAuthenticated = useSelector(selectIsAuthenticated);

  return (
    <Modal
      id="SocureServiceDownModal"
      className="socure-service-down-modal"
      disableOnClickOutside
      disableCloseButton
    >
      <h2 className="h1 modal-title">
        <Trans
          file="Errors"
          id="ServiceDownModal_Title"
          fallback="Houston, we have a problem."
        />
      </h2>
      <div className="modal-content">
        <div className="modal-graphic">
          <ServerErrorSvg role="presentation" />
        </div>
        <p className="service-down-description">
          <Trans 
            file="Errors"
            id="SocureServiceDownModal_Content"
            fallback="Account services are unavailable at this time. Please try again after"
          />
          {' '}
          {data && data.errors && data.errors.ERROR[0]}
          {' '}
          {getCentralOffset()}
        </p>
        <FormActions align="center">
        <FormActionsItem>
            <ButtonPrimary
              onClick={() => {
                dispatch(closeModal('SocureServiceDownModal'));

                if (serviceType === 'stepUp' || serviceType === 'forgotUsername') {
                  sendMessageToNativeApp({
                    ...APP_CONSTANTS.SOCURE_DOWN_MSG,
                    timestamp: new Date().toISOString(),
                  })
                }
              }}
            >
            <Trans
              file="Labels"
              id={isAuthenticated ? 'BackToDashboard' : 'BackToSignIn'}
            />
            </ButtonPrimary>
        </FormActionsItem>
        </FormActions>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ modal }): Object => ({ modal });
const mapDispatchToProps = dispatch =>
  bindActionCreators({ closeModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SocureServiceDownModal);