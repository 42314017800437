// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// Components
import Trans from '../Trans/Trans';
import { TextButton } from 'texkit/dist/components/Buttons';

// Actions
import { getServiceStatus } from '../../../reducers/service/serviceActions';

// Styles
import './limited-experience-notice.scss';

const LimitedExperienceNotice = ({ id, className }) => {
  const dispatch = useDispatch();

  return (
    <div className={className}>
      <div className="message">
        <Trans file="Alerts" id={id} fallback={id} />
      </div>
      <div>
        <TextButton
          className="text-button"
          onClick={() => {
            dispatch(getServiceStatus('stepUp'));
          }}
        >
          <Trans file="Alerts" id="Over18" fallback="Over18" />
        </TextButton>
      </div>
    </div>
  );
};

LimitedExperienceNotice.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default LimitedExperienceNotice;
