// Vendor
import { push } from 'connected-react-router';

// Requests
import { getServiceStatusRequest } from '../../backend/requests';

// Helpers
import { to } from '../../lib/helpers';
import { openModal } from '../modal/modalActions';

export const getServiceStatus = service => async dispatch => {
    const [{ response, body }] = await to(
        dispatch(
            getServiceStatusRequest(service)
        )
    );

    if (body.status === 'ERROR') {
        return dispatch(openModal('SocureServiceDownModal', { ...body, service }))
    };

    let action;
    if (service === 'forgotUsername') {
        action = push('/forgot-username');
    } else if (service === 'createAccount') {
        action = push('/create-account');
    } else if (service === 'stepUp') {
        action = openModal('StepUpModal');
    }

    return dispatch(action);
};